import logo from './tectrol.svg'

const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

function EscritaTermo({empresa, cnpj, nome, email, ddd, telefone}){

    return(
        <div className="leitura-termo">
            <h1 >TERMO DE COMPARTILHAMENTO DE DADOS PESSOAIS</h1>
            <p>Pelo presente termo <b>TECTROL AUTOMAÇÃO INDUSTRIAL LTDA</b>, inscrita no CNPJ/MF sob o
            nº 01.478.398/0001-39 ao final assinado, ora nominado de <b>CONTROLADOR</b> e <b>RAZÃO SOCIAL DO CLIENTE </b>
             <b>{empresa}</b>, inscrita
            no CNPJ sob nº {cnpj} ao
             final assinado ora nominado de <b>OPERADOR</b>, ou, em conjunto nominados como <b>PARTES</b>.
            Resolvem celebrar o presente termo que se regerá pelas seguintes cláusulas e condições:</p>

            <h2>CLÁUSULA PRIMEIRA: OBJETO</h2>
            <p>1.1. Em razão da relação existentes entre as partes, O(A) OPERADOR receberá do O(A)
            CONTROLADOR dados pessoais e pessoais sensíveis de técnicos, designados para prestar
            serviços conforme contrato, dentro das dependências da empresa OPERADOR (A).</p>
            <p>1.2. Para os serviços descritos na cláusula 1.1, serão compartilhados os dados dos
            colaboradores, exigidos para a realização do serviço.</p>
            <p>1.3. Para tanto, <b>O(A) OPERADOR assume o compromisso de tratar os dados pessoais a que
            tiver acesso em razão deste termo, exclusivamente para a prestação de serviços
            designados como o objeto da negociação entre as partes, conforme cláusula 1.1.</b></p>
            <p>1.4. AS PARTES declaram que no presente caso, a base legal para o tratamento de dados, será o
            de execução de termo, conforme art. 7º, V, da Lei 13.709/2018.</p>

            <h2>CLÁUSULA SEGUNDA: PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS</h2>
            <p>2.1. O(A) OPERADOR compromete-se a realizar o tratamento dos dados pessoais a que tiver
            acesso por força deste termo, em consonância com as determinações da Lei 13.709/2018, bem
            como com as demais legislações de privacidade aplicáveis. Para isso, O(A) OPERADOR cumprirá
            todas as determinações legais para proteção dos dados pessoais que estiverem em sua custódia,
            atendendo especialmente aos princípios de adequação, necessidade e finalidade, e limitará
            internamente o acesso aos dados aos colaboradores estritamente necessários ao atendimento da
            finalidade.</p>
            <p>2.2. Os dados pessoais e dados pessoais sensíveis decorrentes da relação entre AS PARTES o
            termo são de acesso exclusivo do(a) OPERADOR, não podendo ser vendidos, compartilhados,
            transferidos ou cedidos a terceiros.</p>

            <h2>CLÁUSULA TERCEIRA: SIGILO, CONFIDENCIALIDADE E LICITUDE DA FONTE</h2>
            <p>3.1. Considerando-se o objeto da pactuação, O(A) OPERADOR declara expressamente ter
            conhecimento e consente, acerca da obrigação de guardar sigilo e segredo de todos os dados e
            informações, verbais, escritas ou magnéticos recebidas, em todos os aspectos da relação jurídica
            pactuada, sobre tudo que lhe for revelado ou tomar conhecimento por força do seu exercício
            profissional. Incluem-se nestes, sem limitação, fatos, dados, números, conteúdo de documentos,
            relativos às operações e negócios, segredos e/ou informações financeiras, operacionais,
            comerciais, econômicas, técnicas e jurídicas, pareceres, planos de trabalho, quaisquer cópias ou
            registros, dados pessoais e dados pessoais sensíveis de colaboradores, sócios, clientes, ou
            outros prestadores de serviços vinculados direta ou indiretamente à mesma, que tenham sido
            fornecidas por força da relação existente entre as partes.</p>
            <p>3.2. Os dados coletados e dados pessoais sensíveis são de acesso exclusivo O(A) OPERADOR,
            que declara ter conhecimento da proibição de divulgar, publicar, revelar, repassar, enviar, fazer
            comentários ou – em geral – deixar transparecer de qualquer forma, total ou parcialmente, por
            conta própria ou através de terceiros dados e informações obtidas e as quais teve acesso por
            força das atividades prestadas.</p>
            <p>3.3. Tais informações somente poderão ser reveladas e/ou divulgadas mediante autorização
            expressa e por escrito da outra parte. Caso as partes ou quaisquer de seus representantes sejam
            obrigados, em virtude de lei, decisão judicial ou por determinação de qualquer autoridade
            governamental, a divulgar quaisquer informações confidenciais, deverão comunicar
            imediatamente a outra parte a respeito, de forma que haja possibilidade de a parte afetada tomar
            as medidas cabíveis, inclusive judiciais, para se preservar.</p>

            <h2>CLÁUSULA QUARTA: SEGURANÇA</h2>
            <p>4.1. O(A) OPERADOR assume o compromisso de tomar todas as medidas de segurança
            adequadas, que contenha salvaguardas administrativas, técnicas e físicas adequadas à
            complexidade, natureza e alcance de suas atividades e a sensibilidade dos dados que receberá
            do(a) CONTROLADOR. Essas salvaguardas incluirão, mas não se limitarão a:</p>

            <p>a) Programa de conformidade, conforme art. 50 da Lei nº 13.709/2018; <br/>
            b) Segurança e confidencialidade dos dados pessoais, dados pessoais sensíveis e demais
            informações a que tiver acesso;<br/>
            c) Proteção contra ameaças ou riscos antecipados para a segurança ou integridade dos dados
            pessoais e informações, possibilidade de detecção de vazamentos ou acessos não autorizados,
            bem como plano de resposta a incidentes de segurança;<br/>
            d) Proteção contra acesso não autorizado ou uso de informações do(a) OPERADOR que possam
            resultar em danos ou inconvenientes substanciais para a mesma e/ou seus clientes,
            colaboradores e terceiros;<br/>
            e) Adoção de medidas aptas a proteger os dados pessoais e dados pessoais sensíveis de
            situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de
            tratamento inadequado ou ilícito;<br/>
            f) Controles de segregação, sistemas com restrição e registro de acesso por usuário;<br/>
            g) Termo de confidencialidade com todos os colaboradores e prestadores de serviços que possam
            ter acesso aos dados pessoais objeto deste termo;<br/>
            h) Software originais, seguros e atualizados, etc.;<br/>
            i) Política de privacidade e instruções internas sobre utilização de dados pessoais;<br/>
            j) Garantia de fácil acesso, modificação ou exclusão dos dados, se necessário e registro das
            operações de tratamento realizadas;<br/>
            k) Possibilidade de restaurar dados pessoais e dados pessoais sensíveis em casos de algum
            incidente de segurança (backups seguros);<br/>
            l) Controles internos que testem regularmente a efetividade das medidas de segurança adotadas.</p>

            <h2>CLÁUSULA QUINTA: DIREITO DOS TITULARES</h2>
            <p>5.1. O(A) OPERADOR ficará responsável por auxiliar O(A) CONTROLADOR no atendimento de
            solicitações dos titulares de dados pessoais e deverá garantir a possibilidade de cumprimento dos
            seguintes direitos: a confirmação da existência do tratamento, acesso aos dados,</p>
            <p>5.2. Eventuais solicitações de titulares de dados pessoais, recepcionadas pelo(a) OPERADOR
            serão imediatamente encaminhadas o(a) CONTROLADOR para resposta à requisição.</p>

            <h2>CLÁUSULA SEXTA: RESPONSABILIDADE</h2>
            <p>6.1. O(A) OPERADOR assume total responsabilidade, caso tais dados sejam tratados de forma
            ilegal, caso ocorra qualquer incidente que comprometa a segurança, guarda e/ou vazamento de
            tais dados ou caso terceiros, diretamente vinculados ao CONTROLADOR, descumprirem tais
            obrigações, isentado O(A) CONTROLADOR de qualquer responsabilidade frente ao titular dos
            dados ou perante as autoridades competentes.</p>
            <p>6.2. Fica garantido ao CONTROLADOR o direito a denunciação da lide, ação de regresso ou outra
            ação pertinente, nos termos do Código de Processo Civil, caso a mesma seja demandada por
            qualquer pessoa, autoridade ou entidade, pública ou privada, em razão de vazamento de dados
            e/ou incidentes de segurança que estavam sob custódia do(a) OPERADOR.</p>

            <h2>CLÁUSULA SÉTIMA: INCIDENTES DE SEGURANÇA</h2>
            <p>7.1. O(A) OPERADOR deverá manter plano estruturado que estabeleça medidas de respostas
            para casos de incidente de segurança envolvendo dados pessoais. O documento deverá
            determinar, dentro outros fatores: a) medidas de identificação; b) medidas de classificação; c)
            medidas para resposta e remediação.</p>
            <p>7.2. Em caso de incidente de segurança, vazamentos de dados pessoais e/ou violação das regras
            de sigilo ora estabelecidas, que tenha ocorrido por sua ação ou omissão, independentemente da
            existência de culpa, bem como de seus empregados, prepostos e prestadores de serviço, deverá
            O(A) OPERADOR, notificar, imediatamente, O(A) CONTROLADOR, por escrito e por e-mail
            “dpo.tectrol@gmail.com”, no prazo máximo de até 24 (vinte e quatro) horas a partir do
            conhecimento do incidente de segurança e/ou vazamento de dados, fornecendo todos os detalhes
            relacionados ao incidente, certificando-se do recebimento da comunicação via email.</p>
            <p>7.3. A notificação mencionada na cláusula 7.2, deverá conter minimamente:<br/>
            I - a descrição da natureza dos dados pessoais afetados;<br/>
            II - as informações sobre os titulares envolvidos;<br/>
            III - a indicação das medidas técnicas e de segurança utilizadas para a proteção dos dados,
            observados os segredos comercial e industrial;<br/>
            IV - os riscos relacionados ao incidente;<br/>
            V - os motivos da demora, no caso de a comunicação não ter sido imediata;<br/>
            VI - as medidas que foram ou que serão adotadas para reverter ou mitigar os efeitos do prejuízo,
            dos danos no ocorrido e o risco de recorrência; e<br/>
            VII- Relatório de Impacto de Dados da operação em que ocorreu o incidente de segurança.</p>

            <h2>CLÁUSULA OITAVA: PRAZO DE TRATAMENTO</h2>
            <p>8.1. Os dados pessoais e dados pessoais sensíveis serão mantidos pelo(a) OPERADOR pelo
            período em que forem necessários para atingimento das finalidades legítimas da relação
            contratual existente entre AS PARTES, incluindo finalidades relativas ao cumprimento de
            obrigações legais e regulatórias ou relativas a processos administrativos, judiciais ou arbitrais.</p>
            <p>8.2. Para fins de preservação da privacidade dos dados, AS PARTES se comprometem a reterem
            a menor quantidade possível de dados e registros e excluí-los: a) Tão logo atingida a finalidade de
            seu uso; e b) Se encerrado o prazo determinado por obrigação legal ou contratual, conforme
            legislação pertinente;</p>

            <h2>CLÁUSULA NONA: VIGÊNCIA</h2>
            <p>9.1. O presente TERMO, celebrado entre AS PARTES, entrará em vigor a partir da presente data,
            serão aplicáveis e permanecerão vigentes, mesmo após o término do relacionamento entre AS
            PARTES, enquanto O(A) OPERADOR, continuar a realizar o tratamento de dados pessoais
            compartilhados pelo(a) CONTROLADOR, para a prestação dos serviços anteriormente citados.</p>

            <h2>CLÁUSULA DÉCIMA: GENERALIDADES</h2>
            <p>10.1. Este Termo será regido pelas leis brasileiras e obriga AS PARTES, seus representantes
            legais, sucessores e cessionários, declarando AS PARTES não haver quaisquer outros
            entendimentos, acordos ou declarações, expressas ou implícitas, com relação a este documento
            que não estejam aqui especificadas.</p>
            <p>10.2. Cada parte, neste ato, reconhece e aceita que, na hipótese de violação de quaisquer das
            cláusulas deste Termo, estará ela sujeita a todas as sanções e penalidades nos termos da
            legislação brasileira, sem prejuízo das perdas e danos a que der causa.</p>
            <p>10.3. AS PARTES informam neste ato a identificação e contato do ENCARREGADO DE DADOS,
            qual seja:</p>
            <p>ENCARREGADO DO(A) OPERADOR:<br/>
                Nome: {nome} <br/>
                Email: {email} <br/>
                Telefone: ({ddd}) {telefone}<br/>
            </p>
            <p>ENCARREGADO DO CONTROLADOR:<br/>
                Nome: Cláudia Hokama Utiyama<br/>
                Email: dpo.tectrol@gmail.com <br/>
                Telefone: (43) 3305-2511</p>

            <p>10.1. AS PARTES elegem o Foro Central da Comarca da Londrina (PR), para dirimir quaisquer
            controvérsias ou dúvidas oriundas deste instrumento, com renúncia a qualquer outro, por mais
            privilegiado que seja.</p>

            <p>Londrina (PR), {new Date().getDate()} de {meses[(new Date().getMonth())]} de {new Date().getFullYear()}</p>

        </div>
    )

}

export default EscritaTermo
import shield from './shield-tectrol.svg';
import logo from './tectrol.svg'
import './App.css';
import { useState } from 'react';
import EscritaTermo from './Termo';
import axios from 'axios';

const Area = {
    HOME: 1,
    TERMO: 2,
    FORMULARIO:3
}

const TermoStage = {
    FILL: 1,
    TFA: 2,
    SUCCESS: 3,
    ERROR: 4
}

function Formulario({goBack}){

    const url = 'https://forms.gle/PPXLXxgAC2uFVb668'

    return(
        <div className="App">
            <header className="App-header App-termo">
                <h1>Formulário de Adequação à LGPD</h1>
                <p style={{maxWidth: "60%"}}>Em decorrência de medidas de adequação à <b>Lei Geral de Proteção de Dados</b>, a Tectrol
                está adequando os contratos com todos os envolvidos nas atividades, revisando os
                procedimentos internos para atendimento dos <b>direitos dos titulares</b>, bem como, o
                <b> cumprimento da legislação.</b></p>
                <p style={{maxWidth: "60%"}}>Para tanto, estamos verificando junto aos nossos parceiros a maturidade da
                empresa no tocante ao tema. Pedimos que preencha o questionário abaixo para que possamos melhorar ainda mais nossos serviços.</p>
                
                <text></text>
                <div className='button-small' onClick={() =>  window.open(url, '_blank', 'noopener,noreferrer')} >
                    Acessar formulário
                </div>
                <footer style={{position: 'absolute', bottom: 0}}>
                    <img src={logo} alt="logo" />
                </footer>
            </header>
        </div>
    )

}

function Termo({goBack}){

    const [currentStage, setStage] = useState(TermoStage.FILL)

    const [empresa, setEmpresa] = useState("")
    const [cnpj, setCnpj] = useState("")
    const [nome, setNome] = useState("")
    const [email, setEmail] = useState("")
    const [ddd, setDDD] = useState("")
    const [telefone, setTelefone] = useState("")
    const [aceite, setAceite] = useState(false)
    
    const [tfacode, setTfaCode] = useState("")

    const [isSending, setSending] = useState(false)

    const parseCNPJ = (text) => {
        const x = text.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/)
        setCnpj( !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : ''))
    }

    const parseNumber = (text) => {

        if(text[text.length-1] == '-'){
            setTelefone(text.replace('-', ""))
            return
        }

        if(text[text.length-1] < '0' || text[text.length-1] > '9')
            return

        if(text.length < telefone.length){
            if(text.length < 9){
                setTelefone(text.replace('-', ""))
            }else if(text.length === 9){
                setTelefone(text.replace('-', "").substring(0,4) + "-" + text.replace('-', "").substring(4,8))
            }else{
                setTelefone(text)
            }
            return
        }

        if(text.length === 8){
            setTelefone(text.substring(0,4) + "-" + text.substring(4,8))
        }else if(text.length === 10){
            setTelefone(text.replace('-', "").substring(0,5) + "-" + text.substring(6,10))
        }else{
            setTelefone(text)
        }

    
    }

    const sendForm = (token) => {

        setTimeout(() => {
            // hello!
        }, 1000)

        const data = {
            cnpj,
            email,
            nome,
            ddd,
            telefone,
            empresa
        }

        axios.post('https://tecdin-lgpd-api.azurewebsites.net/api/tec-envia-termo?tk=' + token, data)
            .then(() => {
                setStage(TermoStage.SUCCESS)
            })
            .catch((error) => {
                if(error.response.data){
                    alert(error.response.data.msg)
                }else{
                    alert('Não foi possível enviar. Tente novamente mais tarde.')
                }
            })
            .finally(() => {
                setSending(false)
            })

    }

    const getAuthToken = () => {

        if(isSending)
            return

        if(tfacode.length < 6){
            alert('Código de verificação inválido')
            return
        }

        const data = {
            email,
            codigo_tfa: tfacode
        }

        setSending(true)

        axios.post('https://tecdin-lgpd-api.azurewebsites.net/api/tec-tfa', data)
            .then((response) => {
                sendForm(response.data.token)
            })
            .catch((error) => {
                if(error.response.data){
                    alert(error.response.data.msg)
                }else{
                    alert('Não foi possível enviar. Tente novamente mais tarde.')
                }
                setSending(false)
            })

    }

    const getTfa = () => {

        if(isSending)
            return

        if(empresa.length === 0 || cnpj.length === 0 || nome.length === 0 ||
            email.length === 0 || ddd.length === 0 || telefone.length < 9 ||
            aceite === false){
            alert('Preencha todos os dados para enviar')
            return
        }

        setSending(true)

        axios.post('https://tecdin-lgpd-api.azurewebsites.net/api/tec-tfa', {email})
            .then(() => {
                setStage(TermoStage.TFA)
            })
            .catch(() => {
                alert('Não foi possível enviar. Tente novamente mais tarde.')
            })
            .finally(() => {
                setSending(false)
            })

    }

    const getSuccess = () => {
        return(
            <>
                <h1 style={{marginTop: "3%"}}>Termo de Compartilhamento de Dados Pessoais</h1>
                <p><b>Seu termo foi enviado com sucesso!</b></p>
                <p style={{maxWidth: "60%"}}>Nossa equipe responsável pela LGPD irá analisar os dados enviados junto ao termo e, se tudo estiver certo, iremos validá-lo.
                    Caso algum dado esteja divergente, entraremos em contato.
                </p>
                <p style={{maxWidth: "60%"}}>Você irá acompanhar o status dessa solicitação no e-mail cadastrado ({email}).
                </p>
            </>
        )
    }

    const getTFA = () => {
        return(
            <>
                <h1 style={{marginTop: "3%"}}>Termo de Compartilhamento de Dados Pessoais</h1>
                <p>Nós enviamos um código de verificação para o e-mail: {email}</p>
                <p>Insira-o abaixo para finalizar o aceite do termo.</p>
                <input placeholder='Código de Verificação' value={tfacode} onChange={(e) => setTfaCode(e.target.value)}/>
                <div className='button-small' onClick={getAuthToken}>
                    Enviar
                </div>
            </>
        )
    }

    const getFill = () => {

        return(
            <>
                <img src={logo} alt="logo" style={{marginTop: 60, marginBottom: 50}} />
                <h1>Termo de Compartilhamento de Dados Pessoais</h1>
                <p>Preencha os campos abaixo com os dados referentes à sua empresa e leia atentamente ao termo</p>
                <input placeholder='Nome da Empresa' value={empresa} onChange={(e) => setEmpresa(e.target.value.toLocaleUpperCase())}/>
                <input placeholder='CNPJ' value={cnpj} onChange={(e) => parseCNPJ(e.target.value)}/>
                <input placeholder='Nome do Encarregado de Dados' value={nome} onChange={(e) => setNome(e.target.value)}/>
                <input placeholder='E-mail do Encarregado de Dados'  value={email} onChange={(e) => setEmail(e.target.value)}/>
                <div style={{display: 'flex', flexDirection: "row", marginBottom: 30}}>
                    <input placeholder='DDD'  value={ddd} onChange={(e) => setDDD(e.target.value)}
                        style={{width: 30, marginRight: 10}} maxLength={2}/>
                    <input placeholder='Telefone' value={telefone} onChange={(e) => parseNumber(e.target.value)}
                         style={{width: 155}} maxLength={10}/>
                </div>

                <EscritaTermo empresa={empresa} cnpj={cnpj} nome={nome} email={email} ddd={ddd} telefone={telefone}/>

                <div className='aceite'>
                    <input type="checkbox" style={{alignSelf: "flex-start", width: 30, height: 30, margin: 0}} checked={aceite} onChange={() => setAceite(prev => !prev)}/>
                    <text>Eu, <b>{nome}</b>, Encarregado de Dados da empresa <b>{empresa}</b>, aceito o Termo de Compartilhamento de Dados Pessoais</text>
                </div>

                <div className='button' onClick={getTfa}>
                    Enviar
                </div>

                <footer>
                    <img src={logo} alt="logo" />
                </footer>   
            </>
        )

    }

    const getStage = () => {
        switch(currentStage){
            case TermoStage.FILL:
                return getFill()
            case TermoStage.TFA:
                return getTFA()
            case TermoStage.SUCCESS:
                return getSuccess()
        }

    }

    return(
        <div className="App">
            <header className="App-header App-termo">
                {getStage()}
            </header>
        </div>
    )
}

function Home({goToTermo, goToForms}){
    return (
        <div className="App">
          <header className="App-header">
            <img src={shield} className="App-logo" alt="logo" />
            <h1>Sua privacidade é importante</h1>
            <p>A Tectrol acredita na <b>privacidade dos seus dados</b>.
              De ponta a ponta, todos os dados que trafegam pela nossa infraestrutura 
              passam por um <b>canal seguro</b>, e são criptografados utilizando as últimas 
              tecnologias em <b>ofuscamento</b> e <b>segurança digital</b>.</p>
            <p>Além disso, para cumprir com a <b>Lei Geral de Proteção de Dados</b>,
              nós requerimos que nossos clientes preencham o <b>Termo de Compartilhamento de Dados</b> e 
            <b> Formulário de Adequação à LGPD</b>.</p>
            <p>Se você é nosso cliente e ainda não preencheu o termo, 
              <b> por favor</b>, preencha-o clicando no botão abaixo.</p>
            <div className='button' onClick={goToTermo}>
              Termo de Compartilhamento de Dados
            </div>
            <p>Não se esqueça de também preencher o formulário abaixo
                para sabermos como sua empresa está se adequando à LGPD.</p>
            <div className='button' onClick={goToForms}>
            Formulário de Adequação à LGPD
            </div>
            <footer>
                <img src={logo} alt="logo" />
            </footer>
          </header>
        </div>
      );
}

function App() {
    return <Termo/>
}

export default App;
